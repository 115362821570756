// @flow
import gql from 'graphql-tag';

export const GET_CURRENT_USER = gql`
  query user($uuid: ID!) {
    user(uuid: $uuid) {
      uuid
      id
      firstName
      lastName
      phone
      email
      isExternalUser
      isPayerUser
      twoFactorEnabled
      isPasswordSet
      phone
      chatDisplayName
      bio
      ssoOnly
      role {
        id
        uuid
        name
        displayName
      }
      entities {
        id
        uuid
        name
        parentId
      }
      profilePicture {
        id
        location
      }
      profileVideos {
        id
        userProfileQuestionId
        location
      }
    }
  }
`;
