// @flow

import React, { useState, type Node } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useIntl } from 'react-intl';
import type { Map as MapType } from 'immutable';
import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';

import FormModal from '../../../components/Modals/Form';
import ManageUser from '../../../components/Forms/ManageUser';
import BulkReassignmentForm from './BulkReassignmentForm';
import BulkReassignmentConfirm from './BulkReassignmentConfirm';
import DeleteUserConfirm from './DeleteUserConfirm';
import usePermission from '../../../hooks/usePermission';
import { GET_USER_ASSIGNMENTS } from './queries';
import { BULK_REASSIGN_PATIENTS, DELETE_USER } from './mutations';
import type { User } from '../../../components/ResultsTable';
import type { EntityType } from '../../../components/Forms/ManageUser/types';

type Option = {
  value: string | number,
  label: string,
};

type Props = {
  userModalOpen: boolean,
  formModalTitle: string | Node,
  currentUserUuid: string,
  editingUser: User | null,
  entities: EntityType[],
  roleOptions: Option[],
  analyticGroupOptions?: Option[],
  initialValues: Object,
  twoFactorEnabled?: boolean,
  enableReinitialize?: boolean,
  ssoOnly?: boolean,
  meetYourCareTeamEnabled?: boolean,
  onCloseUserModal: () => void,
  onCancel?: () => void,
  onUserSubmit: (values: MapType<string, any>) => Promise<any>,
};

const ManageUserWrapper = ({
  userModalOpen,
  formModalTitle,
  currentUserUuid,
  editingUser,
  entities,
  roleOptions,
  analyticGroupOptions = [],
  initialValues,
  twoFactorEnabled = false,
  enableReinitialize = false,
  ssoOnly = false,
  meetYourCareTeamEnabled = false,
  onCloseUserModal,
  onCancel = () => {},
  onUserSubmit,
}: Props) => {
  const canBulkReassignPatients = usePermission('users');
  const { formatMessage } = useIntl();
  const [selectedEntityId, setSelectedEntityId] = useState();
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
  const [bulkReassignmentModalOpen, setBulkReassignmentModalOpen] = useState(false);
  const [bulkReassignmentConfirmationOpen, setBulkReassignmentConfirmationOpen] = useState(false);
  const [bulkReassignmentData, setBulkReassignmentData] = useState({});

  const {
    loading: loadingAssignments,
    data: {
      user: { careTeamPatients = [], reviewedPatients = [], appointments = [] } = {},
      assignmentsCountByEntity: { upcomingAppointmentsCount = [], patientsCount = [] } = {},
    } = {},
  } = useQuery(GET_USER_ASSIGNMENTS, {
    variables: { uuid: editingUser?.uuid },
    fetchPolicy: 'cache-and-network',
    skip: !canBulkReassignPatients || !editingUser,
  });

  const [bulkReassignPatients, { loading: reassigningPatients }] = useMutation(BULK_REASSIGN_PATIENTS, {
    refetchQueries: ['users', { query: GET_USER_ASSIGNMENTS, variables: { uuid: currentUserUuid } }],
  });

  const [deleteUser, { loading: deletingUser }] = useMutation(DELETE_USER, {
    refetchQueries: ['users', 'licenses'],
  });

  const isCurrentUser = editingUser && currentUserUuid === editingUser.uuid;
  const upcomingAppointments = appointments.filter(({ status }) => status === 'UPCOMING');
  const userHasAssignments = careTeamPatients.length || reviewedPatients.length || upcomingAppointments.length;

  const entityIds = entities.map(({ id }) => id);
  const canDeleteUser = uniqBy([...upcomingAppointmentsCount, ...patientsCount], 'entityId')
    .map(({ entityId }) => entityId)
    .every((entityId) => entityIds.includes(entityId));
  const careTeamPatientsByEntity = groupBy(careTeamPatients, (patient) => patient.entity.id);
  const reviewedPatientsByEntity = groupBy(reviewedPatients, (patient) => patient.entity.id);
  const patientsWithAppointmentsByEntity = groupBy(
    uniqBy(
      upcomingAppointments.map(({ patient }) => patient),
      'uuid',
    ),
    (patient) => patient.entity.id,
  );
  const appointmentsByEntity = groupBy(upcomingAppointments, ({ patient }) => patient.entity.id);
  const selectedEntityCareTeamPatients = careTeamPatientsByEntity[selectedEntityId] || [];
  const selectedEntityReviewedPatients = reviewedPatientsByEntity[selectedEntityId] || [];
  const selectedEntityAllPatientsCount = (
    uniqBy([...selectedEntityCareTeamPatients, ...selectedEntityReviewedPatients], 'uuid') || []
  ).length;
  const selectedEntityAppointmentCount = (appointmentsByEntity[selectedEntityId] || []).length;

  const selectedEntity = entities.find(({ id }) => id === selectedEntityId);
  const defaultCareTeamMemberUuid = selectedEntity?.defaultCareTeamMember?.uuid;
  const defaultReviewerUuid = selectedEntity?.defaultReviewer?.uuid;

  const handleOpenDeleteConfirmationModal = () => {
    setDeleteConfirmationModalOpen(true);
    onCloseUserModal();
  };

  const handleCloseDeleteConfirmationModal = () => {
    setDeleteConfirmationModalOpen(false);
    onCancel();
  };

  const handleOpenBulkReassignmentModal = (entityId) => {
    setSelectedEntityId(entityId);
    setBulkReassignmentModalOpen(true);
    onCloseUserModal();
  };

  const handleCloseBulkReassignmentModal = () => {
    setBulkReassignmentModalOpen(false);
    onCancel();
  };

  const handleOpenBulkReassignmentConfirmation = (data) => {
    setBulkReassignmentModalOpen(false);
    setBulkReassignmentData(data);
    setBulkReassignmentConfirmationOpen(true);
  };

  const handleCloseBulkReassignmentConfirmation = () => {
    setBulkReassignmentConfirmationOpen(false);
    onCancel();
  };

  const handleSubmitBulkReassignPatients = async () => {
    const { careTeamMember, reviewer, appointmentAssignee } = bulkReassignmentData;
    await bulkReassignPatients({
      variables: {
        entityId: selectedEntityId,
        userUuid: editingUser?.uuid,
        careTeamUuid: careTeamMember?.uuid,
        reviewerUuid: reviewer?.uuid,
        appointmentAssigneeUuid: appointmentAssignee?.uuid,
      },
    });
    setBulkReassignmentConfirmationOpen(false);
    onCancel();
  };

  const handleDeleteUser = async () => {
    await deleteUser({ variables: { uuid: editingUser?.uuid } });
    handleCloseDeleteConfirmationModal();
  };

  const getUserDisplayName = (user, defaultLabelId) =>
    user ? `${user.firstName} ${user.lastName}` : formatMessage({ id: defaultLabelId });

  return (
    <>
      <FormModal open={userModalOpen} title={formModalTitle}>
        <ManageUser
          editing={!!editingUser}
          isCurrentUser={isCurrentUser}
          roles={roleOptions}
          analyticGroups={analyticGroupOptions}
          initialValues={initialValues}
          entities={entities}
          editingUserEntities={editingUser?.entities}
          enableReinitialize={enableReinitialize}
          onDelete={handleOpenDeleteConfirmationModal}
          onCancel={onCloseUserModal}
          onSubmit={onUserSubmit}
          uuid={editingUser && editingUser.uuid}
          careTeamPatientsByEntity={careTeamPatientsByEntity}
          reviewedPatientsByEntity={reviewedPatientsByEntity}
          patientWithAppointmentsByEntity={patientsWithAppointmentsByEntity}
          loadingAssignments={loadingAssignments}
          userHasAssignments={userHasAssignments}
          canDeleteUser={canDeleteUser}
          onBulkReassignmentModalOpen={handleOpenBulkReassignmentModal}
          isExternalUser={editingUser?.isExternalUser}
          twoFactorEnabled={twoFactorEnabled}
          isPasswordSet={editingUser?.isPasswordSet ?? false}
          ssoOnly={ssoOnly}
          meetYourCareTeamEnabled={meetYourCareTeamEnabled}
        />
      </FormModal>
      {!!editingUser && (
        <>
          {canBulkReassignPatients && !!selectedEntityId && (
            <>
              <BulkReassignmentForm
                key={selectedEntityId}
                open={bulkReassignmentModalOpen}
                entityId={selectedEntityId}
                editingUser={editingUser}
                careTeamPatientsCount={selectedEntityCareTeamPatients.length}
                reviewedPatientsCount={selectedEntityReviewedPatients.length}
                totalPatientCount={selectedEntityAllPatientsCount}
                appointmentCount={selectedEntityAppointmentCount}
                defaultCareTeamMemberUuid={defaultCareTeamMemberUuid}
                defaultReviewerUuid={defaultReviewerUuid}
                onClose={handleCloseBulkReassignmentModal}
                onSave={handleOpenBulkReassignmentConfirmation}
              />
              <BulkReassignmentConfirm
                open={bulkReassignmentConfirmationOpen}
                editingUserName={getUserDisplayName(editingUser, '')}
                hasPatients={!!selectedEntityAllPatientsCount}
                hasAppointments={!!selectedEntityAppointmentCount}
                careTeamMemberName={getUserDisplayName(
                  bulkReassignmentData.careTeamMember,
                  'app.user.bulk-reassignment.unassigned',
                )}
                reviewerName={getUserDisplayName(
                  bulkReassignmentData.reviewer,
                  'app.user.bulk-reassignment.unassigned',
                )}
                appointmentAssigneeName={getUserDisplayName(
                  bulkReassignmentData.appointmentAssignee,
                  'app.user.bulk-reassignment.na',
                )}
                submitting={reassigningPatients}
                onClose={handleCloseBulkReassignmentConfirmation}
                onSubmit={handleSubmitBulkReassignPatients}
              />
            </>
          )}

          <DeleteUserConfirm
            open={deleteConfirmationModalOpen}
            userName={getUserDisplayName(editingUser, '')}
            submitting={deletingUser}
            onClose={handleCloseDeleteConfirmationModal}
            onSubmit={handleDeleteUser}
          />
        </>
      )}
    </>
  );
};

export default ManageUserWrapper;
