// @flow

import React, { type Node, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Select, { components as reactComponents, ValueContainerProps } from 'react-select';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import { createFilter } from 'react-select/dist/react-select.cjs.prod';
import { List } from 'immutable';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Skeleton from '@material-ui/lab/Skeleton';
import Icon from '@material-ui/core/Icon';
import noop from 'lodash/noop';
import flatMap from 'lodash/flatMap';
import startCase from 'lodash/startCase';
import classNames from 'classnames';
import every from 'lodash/every';
import reduce from 'lodash/reduce';

import Checkmark from '../../Icons/Checkmark';
import TextHighlight from '../../TextHighlight';
import Checkbox from '../Checkbox';

import styles from './styles.module.scss';

export type Option = { value: any, label: string, disabled?: boolean };
export type GroupedOptions = { label: string, options: Array<Option | (Option & { customStyles?: Object })> };
export type Options = Array<Option> | Array<GroupedOptions>;

type Props = {
  disabled?: boolean,
  input: {
    name?: string,
    value?: any,
    onChange: (inputValue?: any) => void,
    onBlur?: (inputValue?: any) => void,
    onFocus?: (inputValue: string) => void,
    onInput?: (inputValue: string) => void,
    onCreate?: (inputValue: string) => void,
  },
  label?: string | Node,
  meta?: {
    touched: boolean,
    error: string,
  },
  extraRootClasses?: string,
  extraLabelClasses?: string,
  extraSelectComponentProps?: Object,
  placeholder?: string | Node,
  options?: Options,
  components?: Object,
  menuPlacement?: 'bottom' | 'auto' | 'top',
  maxMenuHeight?: number,
  menuPosition?: string,
  isMulti?: boolean,
  loading?: boolean,
  isAsync?: boolean,
  defaultOptions?: Options,
  cacheOptions?: boolean,
  asyncPromiseFilter?: (inputValue: string, callback: (Array<any>) => void) => Promise<Array<any>>,
  required?: boolean,
  capitalizeOptions?: boolean,
  sortOptions?: boolean,
  isCreatable?: boolean,
  isClearable?: boolean,
  useObject?: boolean,
  allowSelectAll?: boolean,
};

const ALL_OPTION_PLACEHOLDER_VALUE = '*';
const ALL_OPTION = { label: 'All', value: ALL_OPTION_PLACEHOLDER_VALUE };

const ValueContainer = ({ children, ...otherProps }: ValueContainerProps) => {
  const optionsCount = otherProps.options.filter((opt) => opt.value !== ALL_OPTION_PLACEHOLDER_VALUE).length;
  const selectedValuesCount = otherProps.selectProps.value.length;
  const areAllSelected = selectedValuesCount === optionsCount;
  const showAllLabel =
    otherProps.selectProps.allowSelectAll && areAllSelected && every(otherProps.options, (opt) => 'value' in opt);

  return (
    <>
      {selectedValuesCount > 0 && (
        <div
          className={classNames(styles.multiValueClear, {
            [styles.multiValueClearAll]: showAllLabel,
          })}
        >
          {!showAllLabel && <p>{selectedValuesCount}</p>}
          {showAllLabel && <FormattedMessage tgaName="p" id="app.dropdown.all" />}
          <Icon
            onClick={() => {
              otherProps.clearValue();
              otherProps.selectProps.onMenuClose();
            }}
            data-testid="multi-select-clear"
          >
            clear
          </Icon>
        </div>
      )}
      <reactComponents.ValueContainer {...otherProps}>{children}</reactComponents.ValueContainer>
    </>
  );
};

const Dropdown = (props: Props) => {
  const [inputValue, setInputValue] = useState('');

  const {
    disabled = false,
    placeholder = 'Select...',
    options = [],
    label,
    input,
    extraRootClasses = '',
    extraLabelClasses = '',
    extraSelectComponentProps = {},
    meta: { touched, error } = { touched: true, error: '' },
    components = {},
    menuPlacement = 'bottom',
    maxMenuHeight = 250,
    menuPosition = 'absolute',
    isMulti = false,
    loading = false,
    isAsync = false,
    defaultOptions = [],
    cacheOptions = false,
    asyncPromiseFilter = noop,
    required = false,
    capitalizeOptions = false,
    sortOptions = true,
    isCreatable = false,
    isClearable = false,
    useObject = false,
    allowSelectAll = false,
    ...rest
  } = props;
  const { formatMessage } = useIntl();

  const { name, value, onChange, onBlur = noop, onFocus = noop, onInput = noop, onCreate = noop } = input;
  const useObjectParams = useObject ? { value } : {};

  const selectAllEnabled = allowSelectAll && every(options, (opt) => 'value' in opt);

  const singleChangeHandler = (func: Function) =>
    function handleSingleChange(v: any, event: any) {
      if (isMulti) {
        if (!v) return func([]);

        if (selectAllEnabled) {
          const allOptionsSelected =
            v.filter((o) => o.value !== ALL_OPTION_PLACEHOLDER_VALUE).length === options.length;

          if (event.option.value === ALL_OPTION_PLACEHOLDER_VALUE && !allOptionsSelected) {
            const optionsToSelect = reduce(
              options,
              (acc, opt) => {
                if (opt.value && opt.value !== ALL_OPTION_PLACEHOLDER_VALUE) {
                  return [...acc, opt.value];
                }

                return acc;
              },
              [],
            );

            return func(optionsToSelect);
          }

          if (event.option.value === ALL_OPTION_PLACEHOLDER_VALUE && allOptionsSelected) {
            return func([]);
          }
        }

        const selectedOption = v.find((el) => el?.value);
        const duplicateValue = v.find((el) => el === selectedOption.value);
        return func(
          v
            .filter(
              (el) =>
                !List.isList(el) && !(duplicateValue && (el?.value === selectedOption.value || el === duplicateValue)),
            )
            .map((el) => (el?.value ? el.value : el)),
        );
      }

      return useObject ? func(v !== null ? v : {}) : func(v ? v.value : '');
    };

  const onInputWrapper = (func: Function) =>
    function handleInputChange(v) {
      setInputValue(v);
      func(v);
    };

  const transformValue = (aValue: any, allOptions: Array<Object>) => {
    let opts = [];
    if (aValue !== null && typeof aValue === 'object') return aValue;
    if (allOptions.length > 0) opts = allOptions;
    if (allOptions.length > 0 && 'options' in allOptions[0]) {
      opts = flatMap(allOptions, (optGroup) => optGroup.options);
    }
    return opts.filter((option) => option.value === aValue);
  };
  const transformedValue = transformValue(value, options);

  const checkboxValue = (option) => {
    if (
      selectAllEnabled &&
      option.value === ALL_OPTION_PLACEHOLDER_VALUE &&
      transformedValue.length === options.length
    ) {
      return true;
    }

    return transformedValue ? transformedValue.includes(option.value) : false;
  };

  const handleSortSingularOptions = (inputOptions: Array<Option>) => {
    if (!Array.isArray(inputOptions)) return inputOptions;

    return [...inputOptions].sort((a, b) => {
      if (!isMulti) return a.label.localeCompare(b.label);
      if (!a.customStyles && b.customStyles) return -1;
      if (a.customStyles && !b.customStyles) return 1;
      if ((checkboxValue(a) || a.label === 'None') && !checkboxValue(b)) return -1;
      if (!checkboxValue(a) && (checkboxValue(b) || b.label === 'None')) return 1;
      return a.label.localeCompare(b.label);
    });
  };

  const handleSortGroupedOptions = (inputOptions: Array<GroupedOptions>) => {
    const optionsToSort = [...inputOptions].map((o) => ({
      label: o.label,
      options: handleSortSingularOptions(o.options),
    }));

    return optionsToSort.sort((a, b) => {
      if (!isMulti) return a.label.localeCompare(b.label);
      if (a.options.some((o) => checkboxValue(o)) && b.options && b.options.every((o) => !checkboxValue(o))) return -1;
      if (a.options.every((o) => !checkboxValue(o)) && b.options.some((o) => checkboxValue(o))) return 1;
      return a.label.localeCompare(b.label);
    });
  };

  const handleSortOptions = (inputOptions: any) => {
    if (Array.isArray(inputOptions) && inputOptions.every((o) => 'options' in o))
      return handleSortGroupedOptions(inputOptions);
    return handleSortSingularOptions(inputOptions);
  };

  const [sortedOptions, setSortedOptions] = useState([]);
  const [sortedDefaultOptions, setSortedDefaultOptions] = useState([]);

  const updateSortedOptions = () => {
    setSortedOptions(sortOptions ? handleSortOptions(options) : options);
    setSortedDefaultOptions(sortOptions ? handleSortOptions(defaultOptions) : defaultOptions);
  };

  const availableOptions = selectAllEnabled ? [ALL_OPTION, ...sortedOptions] : sortedOptions;

  const renderSelect = () => {
    const optionLabel = (option) => {
      const currentValue = useObject ? value?.value : value;

      return (
        <div
          className={classNames(styles.option, {
            [styles.multiSelectOption]: isMulti,
            [styles.optionDisabled]: isMulti && option.disabled,
          })}
        >
          {isMulti && (
            <Checkbox input={{ value: checkboxValue(option) }} classes={{ root: styles.checkbox }} disableRipple />
          )}
          <TextHighlight
            text={capitalizeOptions ? option.label.replace(/\w+/g, startCase) : option.label}
            matchedText={inputValue}
            caseInsensitive
          />
          {option.value === currentValue ? <Checkmark style={{ width: 24, height: 24 }} /> : null}
        </div>
      );
    };

    const filterOption = createFilter({
      matchFrom: 'any',
      stringify: (option) => option.label.props?.children[1]?.props?.text || option.label,
    });

    const controlStyles = (providedStyles, state) => {
      const { menuIsOpen } = state;
      return {
        ...providedStyles,
        borderColor: menuIsOpen && '#f9d849 !important',
        "& [class$='-indicatorContainer']": { transform: menuIsOpen && 'rotate(180deg)' },
      };
    };

    const optionStyles = (providedStyles, state) => {
      const { data: { customStyles = {} } = {}, isFocused } = state;
      return {
        ...providedStyles,
        ...customStyles,
        color: '#171717',
        backgroundColor: isFocused && '#f4f4f4',
      };
    };

    const menuStyles = (provided) => ({ ...provided, position: menuPosition });

    const commonStyles = { control: controlStyles, option: optionStyles, menu: menuStyles };

    const customComponents = isMulti ? { ...components, ValueContainer } : { ...components };

    if (isCreatable)
      return (
        <CreatableSelect
          value={transformedValue}
          isDisabled={disabled}
          inputId={name}
          maxMenuHeight={maxMenuHeight}
          className={classNames(styles.selectSelect, {
            [styles.selectDisabled]: disabled,
            [styles.selectError]: touched && error,
          })}
          placeholder={placeholder}
          isClearable={false}
          options={sortedOptions}
          onCreateOption={onCreate}
          getOptionLabel={optionLabel}
          filterOption={filterOption}
          styles={{ ...commonStyles }}
          onChange={singleChangeHandler(onChange)}
          onInputChange={onInputWrapper(onInput)}
          onBlur={() => onBlur()}
          onMenuOpen={updateSortedOptions}
          formatCreateLabel={(createInput) => formatMessage({ id: 'app.forms.create.new' }, { label: createInput })}
          isLoading={loading}
          isMulti={isMulti}
          closeMenuOnSelect={!isMulti}
          hideSelectedOptions={false}
          controlShouldRenderValue={!isMulti}
          menuPlacement={menuPlacement}
          menuShouldScrollIntoView
          components={customComponents}
          {...extraSelectComponentProps}
        />
      );

    if (isAsync)
      return (
        <AsyncSelect
          valueKey="value"
          value={transformedValue}
          name={name}
          maxMenuHeight={maxMenuHeight}
          className={classNames(styles.selectSelect, {
            [styles.selectDisabled]: disabled,
            [styles.selectError]: touched && error,
          })}
          isDisabled={disabled}
          placeholder={placeholder}
          isClearable={isClearable}
          getOptionLabel={optionLabel}
          filterOption={filterOption}
          styles={{ ...commonStyles }}
          onChange={singleChangeHandler(onChange)}
          onInputChange={onInputWrapper(onInput)}
          onBlur={() => onBlur(value)}
          onFocus={onFocus}
          onMenuOpen={updateSortedOptions}
          defaultOptions={sortedDefaultOptions}
          cacheOptions={cacheOptions}
          loadOptions={asyncPromiseFilter}
          isMulti={isMulti}
          closeMenuOnSelect={!isMulti}
          hideSelectedOptions={false}
          controlShouldRenderValue={!isMulti}
          menuPlacement={menuPlacement}
          menuShouldScrollIntoView
          components={customComponents}
          {...useObjectParams}
          {...extraSelectComponentProps}
        />
      );

    return (
      <Select
        valueKey="value"
        name={name}
        maxMenuHeight={maxMenuHeight}
        options={availableOptions}
        getOptionLabel={optionLabel}
        filterOption={filterOption}
        value={transformedValue}
        className={classNames(styles.selectSelect, {
          [styles.selectDisabled]: disabled,
          [styles.selectError]: touched && error,
        })}
        isDisabled={disabled}
        placeholder={placeholder}
        isClearable={isClearable}
        styles={{ ...commonStyles }}
        onChange={singleChangeHandler(onChange)}
        onBlur={() => onBlur()}
        onFocus={onFocus}
        onMenuOpen={updateSortedOptions}
        formatGroupLabel={(data) => (
          <div>
            <span>{data.label}</span>
          </div>
        )}
        onInputChange={onInputWrapper(onInput)}
        components={customComponents}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        controlShouldRenderValue={!isMulti}
        hideSelectedOptions={false}
        menuPlacement={menuPlacement}
        menuShouldScrollIntoView
        isOptionDisabled={(option) => option.disabled}
        allowSelectAll={allowSelectAll}
        {...extraSelectComponentProps}
      />
    );
  };

  return (
    <FormControl
      data-testid="dropdown"
      error={touched && Boolean(error)}
      classes={{ root: classNames(styles.formControlRoot, extraRootClasses) }}
      fullWidth
      required={required}
      {...rest}
    >
      {label && (
        <InputLabel
          htmlFor={input.name}
          classes={{
            root: classNames(styles.inputLabelRoot, extraLabelClasses, { [styles.inputLabelRootDisabled]: disabled }),
            formControl: styles.inputLabelFormControl,
            shrink: styles.inputLabelShrink,
          }}
          shrink
        >
          {label}
        </InputLabel>
      )}

      {loading ? <Skeleton className={styles.selectSkeleton} /> : renderSelect()}

      {touched && Boolean(error) && (
        <FormHelperText classes={{ root: styles.helperTextRoot, error: styles.helperTextError }} error>
          <FormattedMessage id={error} />
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default Dropdown;
