// @flow

import { Map } from 'immutable';
import type { Map as MapType } from 'immutable';

import { clearSessionStorage } from './sessionStorage';

export type Entity = {
  uuid: string,
  name: string,
  timezone: string,
  chatEnabled: boolean,
  incentivesEnabled: boolean,
  thirdPartyAnalyticsEnabled: boolean,
  bulkSubmissionApproveEnabled: boolean,
  enableVaccinationUpload: boolean,
  importCovidTestResultsEnabled: boolean,
  useRegimenTemplate: boolean,
  enableCaregiver: boolean,
  requireCaregiverInfoForPatientsBelowAge?: number,
  quickEnrollAllPatients: boolean,
  rtw: boolean,
  payerHomePageEnabled: boolean,
  documentCenterEnabled: boolean,
};

export type Permission = {
  name: string,
};

export type Role = {
  uuid: string,
  displayName: string,
  permissions: Array<Permission>,
  isEmochaUser: boolean,
};

export type AuthUser = {
  id: number,
  createdAt: string,
  entities: [
    {
      name: string,
      thirdPartyAnalyticsEnabled: boolean,
      rtw: boolean,
      enableVaccinationUpload: boolean,
      useRegimenTemplate: boolean,
      enableCaregiver: boolean,
      ruleEngineEnabled: boolean,
      requireCaregiverInfoForPatientsBelowAge?: number,
      quickEnrollAllPatients: boolean,
      patientsDashboard?: { enabled: boolean },
      parentId: number,
    },
  ],
  uuid: string,
  firstName: string,
  lastName: string,
  email: string,
  isExternalUser?: boolean,
  isPayerUser?: boolean,
  entities: Array<Entity>,
  role: Role,
};

export type MobileApplicationVersion = {
  platform: string,
  appId: string,
  appVersion: string,
};

export type Auth = {
  token: string | null,
  expiresIn: number | null,
  user: AuthUser,
  failedPasswordAttempt: boolean,
  currentMobileApplicationVersions: Array<MobileApplicationVersion>,
  selectedRootEntities: number[],
  rootEntitiesModalShown: boolean,
};

const subscribers = new Set();

export const INITIAL_AUTH = Map({ user: null, token: null, expiresIn: null });

export const authGet = (): MapType<string, any> => {
  try {
    const authStr = sessionStorage.getItem('auth');

    if (!authStr) {
      throw new Error('No auth');
    }

    return Map(JSON.parse(authStr));
  } catch (e) {
    return INITIAL_AUTH;
  }
};

const notifySubscribers = () => {
  subscribers.forEach((subscriber) => subscriber(authGet()));
};

export const subscribe = (subscriber: (MapType<string, any>) => void) => {
  subscribers.add(subscriber);
  notifySubscribers();

  return () => {
    subscribers.delete(subscriber);
  };
};

export const authSet = (auth: MapType<string, any>): void => {
  sessionStorage.setItem('auth', JSON.stringify(auth));
  notifySubscribers();
};

export const authRemove = (): void => {
  clearSessionStorage();
  notifySubscribers();
};
