// @flow
import React, { useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';
import Backdrop from '@material-ui/core/Backdrop';
import pick from 'lodash/pick';

import Analytics from '../../utils/analytics';
import LoadingIndicator from '../../components/LoadingIndicator';
import ManageUserWrapper from '../WebUsers/ManageUserWrapper';
import FormModal from '../../components/Modals/Form';
import useAuth from '../../hooks/useAuth';
import useMyProfile from '../../hooks/useMyProfile';
import { getProfilePictureUpdate, uploadUserMedia } from './utils';
import { getFileExtension } from '../../utils/file';
import { GET_CURRENT_USER } from './queries';
import { GET_ENTITIES_ROLES } from '../WebUsers/queries';
import { UPDATE_USER } from '../WebUsers/mutations';

const whitelist = [
  'firstName',
  'lastName',
  'email',
  'phone',
  'uuid',
  'newPassword',
  'currentPassword',
  'chatDisplayName',
  'profilePicture',
  'bio',
  'profileVideos',
];

const MyProfile = () => {
  const { user: authUser } = useAuth();
  const { uuid: currentUserUuid } = authUser || {};
  const { profileBackdropOpen, userModalOpen, handleCloseProfileBackdrop, handleCloseUserModal } = useMyProfile();

  const { data: { user } = {}, loading: loadingUsers } = useQuery(GET_CURRENT_USER, {
    skip: !profileBackdropOpen,
    variables: { uuid: currentUserUuid },
    fetchPolicy: 'network-only',
  });

  const { data: entitiesRolesData = {}, loading: loadingEntitiesRoles } = useQuery(GET_ENTITIES_ROLES, {
    skip: !profileBackdropOpen,
    fetchPolicy: 'network-only',
  });

  const { entities = [], roles = [] } = entitiesRolesData;
  const meetYourCareTeamEnabled = entities.some(({ meetYourCareTeamEnabled: featureFlag }) => featureFlag);

  const [updateUser] = useMutation(UPDATE_USER);

  useEffect(() => {
    if (profileBackdropOpen) {
      Analytics.modalview('/my-profile');
    }
  }, [profileBackdropOpen]);

  const handleFormSubmit = async (values) => {
    const { chatDisplayName, profilePicture, profileVideos = [], ...restInput } = pick(values.toJS(), whitelist);
    const profilePictureUpdate = getProfilePictureUpdate(profilePicture);

    const formattedProfileVideos = profileVideos.map(({ userProfileQuestionId, file }) => ({
      userProfileQuestionId,
      ...(file ? { extension: getFileExtension(file), contentType: file.type } : {}),
    }));

    const {
      data: {
        updateUser: { profilePictureUploadLink, profileVideosUploadLinks },
      },
    } = await updateUser({
      variables: {
        input: {
          ...restInput,
          chatDisplayName: chatDisplayName || null,
          ...(meetYourCareTeamEnabled && profilePictureUpdate ? { profilePictureUpdate } : {}),
          ...(meetYourCareTeamEnabled ? { profileVideos: formattedProfileVideos } : {}),
        },
      },
    });

    if (meetYourCareTeamEnabled) {
      await uploadUserMedia({
        profilePicture: { file: profilePicture?.file, uploadLink: profilePictureUploadLink },
        profileVideosUploadLinks: profileVideosUploadLinks ?? [],
        profileVideos,
      });
    }

    handleCloseProfileBackdrop();
  };

  if (!user) return null;

  if (loadingEntitiesRoles || loadingUsers) {
    return (
      <FormModal open title={<FormattedMessage id="app.page.title.profile" />}>
        <LoadingIndicator />;
      </FormModal>
    );
  }

  const roleOptions = roles.map(({ uuid, displayName }) => ({
    value: uuid,
    label: displayName,
  }));

  const additionalFields = {
    roleUuid: user?.role?.uuid,
    entityUuids: user ? user.entities.map(({ uuid, name }) => ({ value: uuid, label: name })) : [],
  };

  return (
    <Backdrop open={profileBackdropOpen} invisible>
      <ManageUserWrapper
        userModalOpen={userModalOpen}
        formModalTitle={<FormattedMessage id="app.page.title.profile" />}
        loadingProfile={loadingEntitiesRoles || loadingUsers}
        currentUserUuid={user.uuid}
        editing
        currentUser
        editingUser={user}
        entities={entities}
        roleOptions={roleOptions}
        initialValues={{
          ...user,
          ...additionalFields,
        }}
        enableReinitialize
        onCancel={handleCloseProfileBackdrop}
        onCloseUserModal={handleCloseUserModal}
        onUserSubmit={handleFormSubmit}
        twoFactorEnabled={user.twoFactorEnabled}
        ssoOnly={user.ssoOnly}
        meetYourCareTeamEnabled={meetYourCareTeamEnabled}
      />
    </Backdrop>
  );
};

export default MyProfile;
