// @flow

import gql from 'graphql-tag';

export const userFragments = {
  info: gql`
    fragment UserInfo on User {
      uuid
      id
      firstName
      lastName
      phone
      email
      isPasswordSet
      role {
        id
        uuid
        name
        displayName
        isEmochaUser
      }
      entities {
        id
        uuid
        name
      }
      analyticGroups {
        id
        name
      }
      patientsCount
    }
  `,
};

export const GET_USERS = gql`
  query users($offset: Int!, $limit: Int!, $order: [[String]]!, $search: String, $entityId: Int) {
    usersCount(search: $search, entityId: $entityId)
    users(offset: $offset, limit: $limit, order: $order, search: $search, entityId: $entityId) {
      ...UserInfo
    }
  }
  ${userFragments.info}
`;

export const GET_ENTITIES_ROLES = gql`
  query rolesAndEntities {
    roles {
      id
      name
      uuid
      displayName
      isEmochaUser
    }
    entities {
      id
      uuid
      name
      defaultCareTeamMember {
        id
        uuid
      }
      defaultReviewer {
        id
        uuid
      }
      meetYourCareTeamEnabled
    }
  }
`;

export const GET_ENTITIES_ROLES_ANALYTICS = gql`
  query rolesAndEntities {
    roles {
      id
      name
      uuid
      displayName
      isEmochaUser
    }
    entities {
      id
      uuid
      name
      parentId
      parent {
        id
        uuid
        name
      }
      defaultCareTeamMember {
        id
        uuid
      }
      defaultReviewer {
        id
        uuid
      }
    }
    analyticGroups {
      id
      name
      entityId
      entityName
    }
  }
`;

export const GET_LICENSES = gql`
  query licenses {
    getLicenses {
      available
      used
    }
  }
`;
